export class AppConfigDto {
  theme: Theme
  shipping: Shipping
  checkout: Checkout
  product: Product

  static create() {
    return new AppConfigDto()
  }
}

export class Checkout {
  taxType: string
  coupon: boolean
  eReceipt: boolean
  trackingDelivery: boolean
}

export class Product {
  type: string[]
  discount: boolean
  specialInstruction: boolean
  imageRequire: boolean
  choiceSelection: boolean
}

export class Shipping {
  methods: string[]
  locationOption: string
}

export class Theme {
  color: string
  logo: string
  splashLogo: string
}