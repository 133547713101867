import { FileDto } from "./file.dto"

import * as _ from 'lodash'

import { PagingDto } from "./paging.dto"
import { CategoryDto } from "./category.dto"
import { flattenAttributes, ProductOptionValue } from "../../+merchant/+product/edit-product/edit-product.utils"
import { TranslationDto } from "./translation.dto"
import { ChoiceGroupDto } from "./choice-group.dto"

export class ProductDto {
  id: string
  sku: string
  name: TranslationDto
  salePrice: number
  enabled: boolean
  adminEnabled: boolean
  stockQuantity: number
  thumbnail?: FileDto
  colorThumbnail?: FileDto
  productAttributes: any
  published: boolean
  allowSelection: boolean
  lowStockAlertQuantity?: number
  specifications?: any
  price: number
  tenant: any
  type: string
  stock: string
  rating: number
  numberOfReviews: number
  weightKg: number

  static create(attributes: any) {
    return _.assign<ProductDto>({
      id: null,
      sku: null,
      name: TranslationDto.create(),
      salePrice: 0,
      stockQuantity: 0,
      lowStockAlertQuantity: 0,
      enabled: true,
      adminEnabled: true,
      thumbnail: null,
      colorThumbnail: null,
      productAttributes: attributes,
      published: false,
      weightKg: null,
      allowSelection: false,
      specifications: []
    })
  }
}

export class FindProductDto extends PagingDto {
  public searchText?: string
  public enabled?: string
  public adminEnabled?: boolean
  public categoryId? : string
  public sort?: string
  public tenantId?: string
  public discounted?: string
}

export class FindProductsAllReview extends PagingDto {
  public searchText?: string
  public categoryId? : string
  public sort?: string
  public tenantId?: string
  public discounted?: string
}

export class ExportProductDto {
  enabled?: string
  tenantId?: string
}

export class ProductDetailDto extends ProductDto {
  options: object[]
  description?: TranslationDto
  images: FileDto[]
  specsImages: FileDto[]
  children: ProductDto[]
  category: CategoryDto
  specifications?: any
  type: string
  weightKg: number
  productChoices: ChoiceGroupDto[]

  static create(attributes: any = null) {
    return _.assign<ProductDetailDto>({
      id: null,
      sku: null,
      parentId: null,
      name: TranslationDto.create(),
      description: TranslationDto.create(),
      salePrice: 0,
      price: 0,
      discount: 0,
      discountType: 'percentage',
      stockQuantity: 0,
      lowStockAlertQuantity: 0,
      enabled: true,
      adminEnabled: true,
      thumbnail: null,
      productAttributes: attributes,
      options: [],
      type: 'stockable',
      stock: 'in-stock',
      images: [],
      specsImages: [],
      children: [],
      category: null,
      published: false,
      allowSelection: false,
      specifications: [],
      weightKg: 0
    })
  }
}

export class CreateOrUpdateProductDto {
  id?: string
  categoryId?: string
  parentId?: string
  thumbnailId?: string
  sku?: string
  name: _.Dictionary<string>
  type: string
  stock: string
  condition?: string
  specification?: string
  salePrice: number
  allowSelection: boolean
  stockQuantity: number
  productAttributes?: any
  options?: any
  discountType?: string
  discount?: number
  description?: _.Dictionary<string>
  lowStockAlertQuantity?: number
  published?: boolean

  static create(data: ProductDto) {
    return _.assign(data)
  }
}

export class CreateProductDto {
  product: ProductDto
  attributes: _.Dictionary<ProductOptionValue>

  static create(data: ProductDto, attributes: _.Dictionary<ProductOptionValue>) {
    const productAttributes = flattenAttributes(attributes)
    const flattenedAttributes = { productAttributes }

    return _.assign(data, flattenedAttributes)
  }
}
