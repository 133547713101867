import { BaseDto } from './base.dto'

import { FileDto } from './file.dto'
import { MallOrderDto } from './mall-order.dto'
import { PagingDto } from './paging.dto'
import { TenantCouponDto } from './tenant-coupon.dto'
import { TenantVoucherDto } from './tenant-voucher.dto'
import { TenantDto } from './tenant.dto'
import { UserDto } from './user.dto'

export enum OrderStatus {
  NEW = 'new',
  COMPLETED = 'completed',
  PICKUP = 'pick-up',
  CANCELLED = 'cancelled',
  REJECTED = 'rejected',
  REFUND = 'refund',
  SHIPPING = 'shipping',
  ACCEPTED = 'accepted',
  DROP_OFF = 'drop_off',
  ON_THE_WAY = 'on_the_way',
  PENDING = 'pending',
  DELIVERED = 'delivered',
  SHIPPED = 'shipped',
  RETURNED = 'returned',
  REQUEST_CANCEL = 'request_cancel',
  REQUEST_RETURN = 'request_return'
}

export enum PaymentStatus {
  PAID = 'paid',
  UNPAID = 'unpaid'
}

export enum PaymentMethod {
  COD = 'cod',
  PAY_WAY = 'payway'
}


export class OrderDto extends BaseDto {
  orderNumber: string
  owner: UserDto
  paidStatus: string
  discountTotal: number
  total: number
  shippingMethod: string
  customerNote: string
  pickupDate: string
  pickupTime: string
  vat: number
  vatRate: number
  paymentStatus: string
  items: OrderItemDto[]
  tenantCouponData: any
  tenantVoucherData: any
  couponTotal: number
  voucherTotal: number
  tenantVoucher: TenantVoucherDto
  tenantCoupon: TenantCouponDto
  tenant: TenantDto
}

export class OrderDetailDto extends BaseDto {
  paymentMethod: string
  paymentStatus: string
  status: string
  logs: any
  shippingAddress: any
  reject: any
  cancel: any
  vat: number
  vatRate: number
  reviewed: boolean
  owner: UserDto
  shippingTotal: number
  trackingNumber: string
  subTotal: number
  total: number
  paidStatus: string
  items: OrderItemDto[]
  orderNumber: string
  discountTotal: number
  voucherTotal: number
  couponTotal: number
  tenantCouponData: any
  tenantVoucherData: any
  tenantVoucher: TenantVoucherDto
  tenantCoupon: TenantCouponDto
  mallOrder: MallOrderDto
  tenant: TenantDto
  mallVoucherShareTotal: number
  mallCouponShareTotal: number
  order: OrderDto
}

export class OrderItemDto {
  quantity: number
  unitPrice: number
  total: number
  subTotal: number
  reviewed: boolean
  unitSalePrice: number
  discountTotal: number
  productDescription: string
  productSlug: string
  thumbnail: FileDto
  productEnabled: boolean
  productName: string
  productAttributes: any[]
}

export class CountStatusDto {
  totalOfNew: number
  totalOfAccepted: number
  totalOfCancelled: number
  totalOfRejected: number
  totalOfRefund: number
  totalOfReadyForPickup: number
  totalOfDropOff: number
  totalOfShipping: number
  totalOfCompleted: number
}

export class FindOrderDto extends PagingDto {
  public searchText?: string
  public status?: string
  public startDate?: Date
  public endDate?: Date
}

export class OrderBadgeCountDto {
  newCount: number
  completedCount: number
  cancelledCount: number
  totalCount: number
}

export class OrderRejectDto {
  reason: any

  static create() {
    return new OrderRejectDto
  }
}

export class ExportOrderDto {
  statuses?: string[]
  startDate?: Date
  endDate?: Date
  timezone?: string
}