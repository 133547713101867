import { Injectable } from "@angular/core"

import { CreateAnnouncementDto, FindAnnouncementDto, PageResultDto, AnnouncementDetailDto, AnnouncementDto, UpdateAnnouncementDto } from "../dto"
import { HttpService } from "./http.service"


@Injectable()
export class AnnouncementService {
  constructor(
    private httpService: HttpService){
  }

  async find({ limit, offset, searchText }: FindAnnouncementDto) {
    return await this.httpService.get<PageResultDto<AnnouncementDto>>('/merchant/v1/announcements', { limit, offset, searchText })
  }

  async findOne(id: string) {
    return await this.httpService.get<AnnouncementDetailDto>(`/merchant/v1/announcements/${id}`)
  }

  async create(createAnnouncementDto: CreateAnnouncementDto) {
    return await this.httpService.post<AnnouncementDto>('/merchant/v1/announcements', createAnnouncementDto)
  }

  async update(id: string, updateAnnouncementDto: UpdateAnnouncementDto) {
    return await this.httpService.put<boolean>(`/merchant/v1/announcements/${id}`, updateAnnouncementDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<boolean>(`/merchant/v1/announcements/${id}`)
  }

  async updateThumbnail(id: string, fileId: string) {
    return await this.httpService.put<boolean>(`/merchant/v1/announcements/${id}/thumbnail`, { fileId })
  }

  async sendNotification(id: string) {
    return await this.httpService.post<boolean>(`/merchant/v1/announcements/${id}/notification`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put<Boolean>(`/merchant/v1/announcements/${id}/enable`)
    }

    return await this.httpService.put<Boolean>(`/merchant/v1/announcements/${id}/disable`)
  }
}