import { CommonModule } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ModuleWithProviders, NgModule } from '@angular/core'

import { HttpErrorInterceptor } from '@api/common'
import { StylesFilterPipe } from '@api/utils'

import { AuthStore, AuthQuery } from '../store/auth'
import { DragulaService } from 'ng2-dragula'


import { HttpService, AuthService, LocalStorageService, FileService, AdminLightningDealService, ProfileService, SettingService, AdminTenantService, AdminUserService, AdminCategoryService, AdminTenantCategoryService, AdminBannerOnboardService, AdminDashboardService, ProductService, OrderService, CategoryService, ProductDiscountService, AdminProductDiscountService, AdminFeaturedTenantCategoryService, AdminFeaturedTenantService, InventoryService, DashboardService, AdminCustomerInquiryService, AdminPromotionService, AdminGalleryService, AdminBannerService, AdminMenuService, TenantService, AdminProductReviewService, ProductReviewService, FloorMapService, AdminProductService, AdminMallVoucherService, TenantVoucherService, AdminPickupCounterService, AdminMallServiceMenuService, AdminOrderService, AdminPageService, AdminTrendingSearchService, WindowRef, AdminMallServicePageService, AdminTenantVoucherService, AdminReportService, MerChantReportService, AdminHomeSectionTitleService, AdminWhitelistPaywayMidService, AdminMallCouponService, AdminTenantCouponService, TenantCouponService, AdminGlobalSettingService, ShippingService, ChoiceGroupService, DeliveryFeeService, AnnouncementService, AppConfigService } from '@api/services'

import { ApiModuleOptions } from './api.module-options'

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [
    StylesFilterPipe
  ],
  providers:[
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    FileService,
    LocalStorageService,
    HttpService,
    AuthService,
    AdminUserService,
    AdminTenantService,
    AdminCategoryService,
    AdminTenantCategoryService,
    AdminDashboardService,
    AdminPageService,
    AdminBannerOnboardService,
    AdminProductDiscountService,
    AdminFeaturedTenantCategoryService,
    AdminProductReviewService,
    AdminFeaturedTenantService,
    AdminTrendingSearchService,
    AdminBannerService,
    AdminTenantVoucherService,
    AdminCustomerInquiryService,
    AdminOrderService,
    AdminPromotionService,
    AdminProductService,
    AppConfigService,
    AdminMallVoucherService,
    AdminMenuService,
    AdminMallServiceMenuService,
    AdminMallServicePageService,
    AdminGalleryService,
    AdminPickupCounterService,
    AdminHomeSectionTitleService,
    AdminWhitelistPaywayMidService,
    AdminReportService,
    AdminMallCouponService,
    AdminLightningDealService,
    AdminTenantCouponService,
    AdminGlobalSettingService,
    ChoiceGroupService,
    DeliveryFeeService,
    AnnouncementService,
    MerChantReportService,
    ProfileService,
    ProductReviewService,
    SettingService,
    ProductService,
    TenantService,
    TenantVoucherService,
    OrderService,
    DragulaService,
    FloorMapService,
    WindowRef,
    CategoryService,
    ProductDiscountService,
    TenantCouponService,
    InventoryService,
    DashboardService,
    ShippingService,
    AuthStore,
    AuthQuery
  ],
  exports: [
    StylesFilterPipe
  ]
})
export class ApiModule {
  static forRoot(options: ApiModuleOptions): ModuleWithProviders<ApiModule> {
    // window['serverURL'] = options.serverURL
    
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiModuleOptions,
          useValue: options
        }
      ]
    }
  }
}
