

import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { ResultDto, ShippingZoneDto, UpdateShippingRateDto, UpdateShippingZoneDto } from '../dto'
import { CreateShippingRateDto, ShippingZoneDetailDto, ShippingZoneListDto } from '../dto/shipping.dto'

@Injectable()
export class ShippingService {
  constructor(
    private httpService: HttpService) {
  }
  
  async zone(profileId: string, zoneId: string) {
    return await this.httpService.get<ShippingZoneDto>('/shipping/profiles/' + profileId + '/zones/' + zoneId)
  }

  async zones() {
    return await this.httpService.get<ShippingZoneDetailDto[]>('/merchant/v1/shipping/zones')
  }

  async updateZone(zoneId: string, { enabled, minDays, maxDays }: UpdateShippingZoneDto) {
    return await this.httpService.put<ResultDto>(`/merchant/v1/shipping/zones/${zoneId}`, { enabled, minDays, maxDays })
  }

  async createRate({ min, cost, shippingZoneId }: CreateShippingRateDto) {
    return await this.httpService.post<ResultDto>('/merchant/v1/shipping/rates', { min, cost, shippingZoneId })
  }

  async updateRate(rateId: string, { cost }: UpdateShippingRateDto) {
    return await this.httpService.put<ResultDto>(`/merchant/v1/shipping/rates/${rateId}`, { cost })
  }

  async deleteRate(rateId: string) {
    return await this.httpService.delete<ResultDto>(`/merchant/v1/shipping/rates/${rateId}`)
  }
}
