import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { SettingDto } from '../dto'
import { StoreScheduleDto, UpdateStoreScheduleDto } from '../dto/store-schedule.dto'

@Injectable()
export class SettingService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<SettingDto[]>('/merchant/v1/setting')
  }

  async update(pageId: string, settingDto: SettingDto) {
    return await this.httpService.put<SettingDto>(`/merchant/v1/setting/${pageId}`, settingDto)
  }

  async findOne(key: string) {
    return await this.httpService.get<SettingDto>(`/v1/settings/${key}`)
  }

    //store close
    async storeSchedule() {
      return await this.httpService.get<StoreScheduleDto[]>(`/merchant/v1/open-hour`)
    }
  
    async updateSchedule(id: string, updateStoreScheduleDto: UpdateStoreScheduleDto) {
      return await this.httpService.put<Boolean>(`/merchant/v1/open-hour/${id}`, updateStoreScheduleDto)
    }
}
