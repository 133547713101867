import { Type } from 'class-transformer'
import * as _ from 'lodash'

import { BaseDto } from './base.dto'
import { FileDto } from './file.dto'
import { PagingDto } from './paging.dto'
import { TranslationDto } from './translation.dto'

export class FindCategoryDto {
  searchText?: string
}

export class FindChildrenCategoryDto extends PagingDto {
  searchText?: string
}

export class CategoryDto extends BaseDto {
  name?: TranslationDto
  level?: number
  hasChildren?: boolean
  description?: TranslationDto
  parentId?: string
  numberOfViews?: number
  link?: string
  walmart?: any

  @Type(() => FileDto)
  bannerImage?: FileDto

  @Type(() => FileDto)
  image?: FileDto

  @Type(() => CategoryDto)
  children?: CategoryDto[]

  @Type(() => CategoryDto)
  parent?: CategoryDto

  static create({ id, name, link, parentId, image, description, bannerImage }: CategoryDto) {
    return _.assign<CategoryDto>({
      id: id || null,
      name: name || TranslationDto.create(),
      description: description || TranslationDto.create(),
      parentId: parentId || null,
      image: image || null,
      bannerImage: bannerImage || null,
      link: link || null
    })
  }
}

export class UpdateCategoryDto {
  name?: TranslationDto
  imageId?: string
  bannerImageId?: string
  link?: string
  walmart?: any
}

export class CreateCategoryDto {
  name: TranslationDto
  parentId?: string
  imageId: string
  bannerImageId: string
  link?: string
  walmart?: any
}