import { BaseDto } from './base.dto'
import { DateOfBirthDto } from './date-of-birth.dto'
import { FileDto } from './file.dto'
import { TenantDto } from './tenant.dto'

export class UserDto extends BaseDto {
  fullName: string
  email: string
  phoneNumber: string
  gender: string
  dateOfBirth: DateOfBirthDto
  enabled: boolean
  photo: FileDto
  roleNames: string[]
  tenant: TenantDto
  tenantId: string
  deletedAt: Date

  static create(userDto?: UserDto) {
    const user =  new UserDto()
    user.fullName = user?.fullName ?? ''
    user.email = user?.email  ?? ''
    user.phoneNumber = user?.phoneNumber  ?? ''
    user.gender = user?.gender  ?? ''
    user.dateOfBirth = user?.dateOfBirth  ?? null
    user.enabled = user?.enabled  ?? null
    user.photo = user?.photo  ?? null
    user.roleNames = user?.roleNames  ?? []
    user.tenant = user?.tenant  ?? TenantDto.create()
    user.tenantId = user?.tenantId ?? null,
    user.deletedAt = user?.deletedAt ?? null,
    user.createdAt = user?.createdAt ?? null, 
    user.id = user?.id ?? null

    return user
  }
}

export enum RoleNames {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  TENANT = 'TENANT',
  MASTER_TENANT = 'MASTER_TENANT',
  ACCOUNT = 'ACCOUNT',
  SALE = 'SALE',
  COUNTER = 'COUNTER',
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE'
}

export class CreateUserDto {
  fullName: string
  email: string
  password: string
  roleName: string
  photoId?: string

  static create(data: CreateUserDto) {
    return Object.assign(new CreateUserDto(), data)
  }
}