import { Injectable } from '@angular/core'

import { FindOrderDto, OrderDto, PageResultDto } from '../dto'
import { CountStatusDto, ExportOrderDto, OrderDetailDto, OrderRejectDto } from '../dto/order.dto'
import { HttpService } from './http.service'


@Injectable()
export class OrderService {
  constructor(
    private httpService: HttpService
  ) { }

  async find(findOrderDto: FindOrderDto) {
    return await this.httpService.get<PageResultDto<OrderDto>>('/merchant/v1/orders', findOrderDto)
  }

  async countStatus() {
    return await this.httpService.get<CountStatusDto>('/merchant/v1/orders/count')
  }

  async exportExcel(dateFilterDto: ExportOrderDto) {
    return await this.httpService.get<any>(`/merchant/v1/orders/export`, dateFilterDto)
  }

  async findOne(tenantOrderNumber: string) {
    return await this.httpService.get<OrderDetailDto>(`/merchant/v1/orders/${tenantOrderNumber}`)
  }

  async accept(tenantOrderNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/accept`)
  }

  async complete(tenantOrderNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/complete`)
  }

  async shipping(tenantOrderNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/ship`)
  }

  async reject(tenantOrderNumber: string, { reason }: OrderRejectDto) {
    return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/reject`, { reason })
  }

  async readyPickup(tenantOrderNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/pickup`)
  }

  async addTrackingNumber(orderId: string, trackingNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${orderId}/trackingNumber`, { trackingNumber })
  }

  async cancel(tenantOrderNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/cancel`)
  }

  async return(tenantOrderNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/return`)
  }

  async completed(tenantOrderNumber: string) {
    return await this.httpService.post(`/merchant/v1/orders/${tenantOrderNumber}/complete`)
  }

}