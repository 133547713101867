import { BaseDto } from "./base.dto"
import { FileDto } from "./file.dto"
import { PageDto } from "./page.dto"
import { UserDto } from "./user.dto"


export class AdminDeliveryListDto extends PageDto {
  blobData: any
  createdBy: string
}

export class DeliveryListDto extends BaseDto {
  blobData: any
  createdBy: string
  file: FileDto
  creator: UserDto
}

export class ExportDeliveryList {
  name?: string
  createdBy?: string
}