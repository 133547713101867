import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { AdminDeliveryListDto, DeliveryListDto } from '../dto/delivery-list.dto'
import { CountStatusDto, ExportOrderDto, OrderRejectDto } from '../dto/order.dto'
import { FindOrderDto, MallOrderDetailDto, MallOrderDto, PageResultDto, ResultDto } from '../dto'
import { PagingDto } from '../dto/paging.dto'


@Injectable()
export class AdminOrderService {
  constructor(
    private httpService: HttpService
  ) {}

  async find(findOrderDto: FindOrderDto) {
    return await this.httpService.get<PageResultDto<MallOrderDto>>('/admin/v1/orders', findOrderDto)
  }

  async findFailDelivery(findOrderDto: FindOrderDto) {
    return await this.httpService.get<PageResultDto<MallOrderDto>>('/admin/v1/orders/fail-delivery', findOrderDto)
  }

  async countStatus() {
    return await this.httpService.get<CountStatusDto>(`/admin/v1/orders/count`)
  }

  async exportExcel(dateFilterDto: ExportOrderDto) {
    return await this.httpService.get<any>(`/admin/v1/orders/export`, dateFilterDto)
  }

  async findOne(mallOrderNumber: string) {
    return await this.httpService.get<MallOrderDetailDto>(`/admin/v1/orders/${mallOrderNumber}`)
  }

  async reject(mallOrderNumber: string, { reason }: OrderRejectDto) {
    return await this.httpService.post(`/admin/v1/orders/${mallOrderNumber}/reject`, { reason })
  }

  async shipping(mallOrderNumber: string) {
    return await this.httpService.post(`/admin/v1/orders/${mallOrderNumber}/shipping`)
  }

  async complete(mallOrderNumber: string) {
    return await this.httpService.post(`/admin/v1/orders/${mallOrderNumber}/complete`)
  }

  async bookNham24(mallOrderNumber: string) {
    return await this.httpService.post(`/admin/v1/orders/${mallOrderNumber}/book-nham24`)
  }

  async updateStatusNham24(mallOrderNumber: string) {
    return await this.httpService.post(`/admin/v1/orders/${mallOrderNumber}/update-logistic-temp-status`)
  }

  async printDeliveryList() {
    return await this.httpService.post<any>(`/admin/v1/orders/print-delivery-list`)
  }

  async printLabelSticker(numberOfPackages: number, mallOrderNumber: string) {
    return await this.httpService.post<any>(`/admin/v1/orders/print-label-stricker`, { numberOfPackages, mallOrderNumber })
  }

  async updateNumOfPackages(mallOrderNumber: string, numberOfPackages: number) {
    return await this.httpService.patch(`/admin/v1/orders/${mallOrderNumber}/number-of-packages`, { numberOfPackages })
  }

  // turn status to ready for pickup (for pickup) or drop off (for delivery)
  // tenant order
  async dropoff(tenantOrderNumber: string) {
    return await this.httpService.post(`/admin/v1/tenant-orders/${tenantOrderNumber}/dropoff`)
  }

  async deliveryList({ limit, offset }: PagingDto) {
    return await this.httpService.get<PageResultDto<DeliveryListDto>>(`/admin/v1/orders/delivery-list-export-history`, { limit, offset })
  }

  async exportPurechaseList({ limit, offset }: PagingDto) {
    return await this.httpService.get<ResultDto>(`/admin/v1/orders/purchase-list-export-history`, { limit, offset })
  }

  async purchaseMorning(date: Date) {
    return await this.httpService.post<any>(`/admin/v1/orders/export-purchase-list-morning`, {date})
  }

  async purchaseAfternoon(date: Date) {
    return await this.httpService.post<any>(`/admin/v1/orders/export-purchase-list-afternoon`, {date})
  }
}