import { CategoryDto, ProductDto, TenantDto } from "."


export class FindDashboardDto {
  startDate?: Date
  endDate?: Date
}

export class DashboardOverviewDto {
  totalSale: number
  totalOrder: number
  totalItemSold: number
  totalCustomers: number

  static create() {
    return new DashboardOverviewDto()
  }
}

export class DashboardTenantOverviewDto {
  totalTenant: number
  totalProduct: number
}

export class DashboardInventoryDto {
  totalInventory: number
  totalLowInventory: number
}

export class DashboardAnalyticsDto {
  totalViewers: number
  totalMaleViewers: number
  totalFemaleViewers: number
  totalUndefinedViewers: number
  categories: CategoryDto[]
  tenants: TenantDto[]
}

export class MerchantDashboardAnalyticsDto {
  totalViewers: number
  totalMaleViewers: number
  totalFemaleViewers: number
  totalUndefinedViewers: number
  categories: CategoryDto[]
  products: ProductDto[]
}