import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { AppConfigDto } from '../dto'

@Injectable()
export class AppConfigService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<AppConfigDto>('/v1/app-config')
  }
}
