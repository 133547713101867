import * as _ from 'lodash'

import { BaseDto } from "./base.dto"
import { FileDto } from "./file.dto"

import { PagingDto } from "./paging.dto"
import { TranslationDto } from './translation.dto'

export class BannerDto extends BaseDto {
  title?: TranslationDto = TranslationDto.create()
  image?: FileDto
  mobileImage?: FileDto
  link?: string
  enabled?: boolean

  static create(data: BannerDto) {
    return _.assign(new BannerDto(), data)
  }
}

export class FindBannerDto extends PagingDto {
  type: string
}

export class CreateBannerDto {
  title: TranslationDto
  imageId?: string
  link?: string
  mobileImageId?: string
  type?: string
}