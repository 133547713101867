import { assign, chain } from 'lodash'

import { BaseDto } from './base.dto'

export class ShippingZoneDto extends BaseDto {
  country: string
  city: string
  minDays: number
  maxDays: number
  enabled: boolean
  provider?: ShippingProviderDto
  rate: ShippingRateDto  
}

export class ShippingZoneDetailDto extends BaseDto {
  city: string
  minDays: number
  maxDays: number
  enabled: boolean
  shippingProviderId?: string
  handler: string
  rates: ShippingRateDto[]
  providerZones?: ShippingZoneDetailDto[]
}

export class ShippingProviderDto extends BaseDto {
  name: string
  sortOrder: number

  static create() {
    return new ShippingProviderDto()
  }
}

export class UpdateShippingProviderDto {
  name?: string
}

export class ShippingRateDto extends BaseDto {
  public min?: number
  public cost: number
  public shippingZoneId?: string

  static create({ id, cost, shippingZoneId }: ShippingRateDto) {
    return assign({}, new ShippingRateDto(), {
      id,
      cost,
      shippingZoneId
    })
  }
}

export class UpdateShippingRateDto {
  cost: number
}

export class UpdateShippingZoneDto {
  minDays: number
  maxDays: number
  enabled: boolean
  providers: object

  static create({ minDays, maxDays, enabled, providerZones }: ShippingZoneDetailDto) {
    const providers = chain(providerZones)
      .keyBy('shippingProviderId')
      .mapValues('enabled')
      .value()

    return assign({}, new UpdateShippingZoneDto(), {
      minDays,
      maxDays,
      enabled,
      providers
    })
  }
}

export class ShippingZoneListDto {
  id: string
  city: string
}

export class DistrictDto {
  id: string
  name: string
  nameKh: string

  static create() {
    return new DistrictDto()
  }
}

export class CreateDistrictDto {
  city: string
  name: string
}

export class UpdateDistrictDto {
  districtId: string
  name?: string
}

export class CreateShippingRateDto {
  shippingZoneId: string
  min: number
  cost: number
}